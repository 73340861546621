import './App.css';
import ecoHero from './assets/eco_hero_phase2.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="text-3xl font-bold">
          Hello world!
        </h1>
        <img src={ecoHero} alt="" height={500} width={300} />
        <p>
          In the near future, this site will have various ideas posted from different disciplines.
        </p>
        <p>
          Enjoy the artowrk, but please do not copy it.
          The artist can be reached <a href="https://www.artstation.com/sauceque"> HERE</a>
        </p>
      </header>
    </div>
  );
}

export default App;
